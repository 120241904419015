import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  TextBox,
  SoftwarePerformance,
  Appointment,
  AppointmentIndividual,
  CallUs,
  QuickFacts,
  BannerLinks,
  BannerCTA,
  Testimonials,
  SplitView,
  ImageList,
  GridList,
  BannerHero
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'
import Georg from '../assets/georg_neu.png'

import backgroundVIdeo from '../assets/hwtechnologievideo.mp4'
import BannerImage from '../assets/header.individual.jpg'
// import BannerImage from '../assets/technologie.jpg'
import JoinTheTeam from '../assets/bannerlinks/jointheteam.jpg'
import VerlaesslicheBeratung from '../assets/bannerlinks/verlaessliche.beratung.jpg'
import Motor from '../assets/software.stack.performance.02.jpg'
import GeorgFrei from '../assets/georg_neu.png'
import RubixCube from '../assets/Rubixcube.png'

import Senoplast from '../assets/senoplast_color.png'
import NHT from '../assets/nht_color.png'
import dibk from '../assets/projectslider/slider.dibk.klein.jpg'
import ak from '../assets/projectslider/slider.aktirol.klein.png'
import stadtamthall from '../assets/projectslider/slider.stadtamthall.klein.png'
import wko from '../assets/projectslider/slider.wko.klein.jpeg'
import internet2 from '../assets/projekte/ikb/internet2.png'
import checkliste from '../assets/projekte/gsk/10.png'

const facts = [
  {
    count: 250,
    suffix: '',
    text: 'Zufriedene Kund:innen'
  },
  {
    count: 100,
    suffix: '%',
    text: 'lokaler Ansprechpartner'
  },
  {
    count: 42798,
    suffix: '',
    text: 'GIT commits'
  }
]

const testimonials = [
  {
    text: "Die Zusammenarbeit mit den verschiedenen Teammitgliedern der Firma Holzweg war zu jedem Projektzeitpunkt sehr angenehm und konstruktiv. Der firmeninterne Informationsfluss funktioniert ausgezeichnet, auch wenn das Projekt dann von der Projektierungs- in die Umsetzungsphase kommt und sich die Ansprechpartner ändern.",
    image: stadtamthall,
    company: "Stadtmarketing Hall in Tirol ",
    name: "Mag. Anny Franzelin M.A."
  },
  {
    text: "Für die Umsetzung eines Projektes mit so speziellen Inhalten und eines so großen Umfanges braucht es einen Partner, der diese Anforderungen professionell und zuverlässig umsetzen kann. Deshalb haben wir uns für die Firma Holzweg entschieden. Die Zusammenarbeit mit der Firma Holzweg war stets konstruktiv und sehr angenehm.",
    image: ak,
    company: 'Wirtschaftskammer Österreich / Tirol ',
    name: "Roland Teißl"
  },
  {
    text: "Sie haben uns von der ersten Stunde an begleitet. Mit innovativen Ideen und kreativen Lösungsvorschlägen, liefern uns die MitarbeiterInnen eine gute Entscheidungsbasis für die Umsetzung unserer Website. Die regelmässigen Workshops helfen uns verstehen, und erleichtern uns die Arbeit. Die Zusammenarbeit ist getragen von einer guten Gesprächsbasis und wir freuen uns, noch weiterhin von Ihrem Know-how profitieren zu können.",
    image: dibk,
    company: "Diözese Innsbruck",
    name: "Dr. Karin Bauer"
  },
  {
    text: "Ein Workshop mit derartigen Inhalten und Aufbereitung ist auf dem Markt einzigartig. Speziell für diese Art von Projekten braucht es zuverlässige Partner wie holzweg. Nicht nur wir als Team erfreuen uns am Ergebnis. Dieses findet vor allem Anklang bei den Schülerinnen und Schülern, die so spielerisch ihren Umgang mit dem Internet hinterfragen können. Wir sind auf die gemeinsame Errungenschaft mit holzweg sehr stolz und freuen uns auf die weitere Zusammenarbeit.",
    image: wko,
    company: "Arbeiterkammer Tirol",
    name: "Dr. Michael Obermeier, BEd."
  },
  {
    text: "Als wir vor der großen Herausforderung standen, für 13 verschiedene Firmen einen Relaunch der Website zu gestalten, war uns bewusst, dass es für diese Aufgabe eine Agentur mit viel Erfahrung mit Multiportalsystemen, Mehrsprachigkeit und komplexen Aufgabensstellungen braucht.",
    image: Senoplast,
    company: "Senoplast",
    name: "Mag. Claudia Pichler"
  },
  {
    text: "Das Intranet dient als Kommunikations-, Wissens- und Informationsplattform des Unternehmens. Die einfache und übersichtliche Handhabung ermöglicht einen raschen Informationsfluss, die Implementierung von verschiedenen Systemen ermöglicht eine effektive Kommunikation. Holzweg GmbH hat für alle individuellen Ansprüche eine Lösung parat, kompetente Ansprechpersonen machen die Zusammenarbeit einfach.",
    image: NHT,
    company: "Neue Heimat Tirol",
    name: "Christina Fally"
  },
];

const boxes = [
  {
    text: 'Join the team!',
    image: JoinTheTeam,
    link: '/karriere',
    linkText: 'Bewerbung'
  },
  {
    text: 'Verlässliche Beratung',
    image: VerlaesslicheBeratung,
    link: '/kontakt',
    linkText: 'Kontakt'
  }
]

const Technologie = () => (
  <Layout>
    <Helmet
      title="Technologie und Programmierung made by holzweg GmbH®"
      meta={[
        {
          name: 'description',
          content:
            'Die holzweg GmbH® bietet Web-Shop Lösungen ✓ PIM ✓ Progressive Web Apps ✓ Individualsoftwarelösungen ✓  CRM ✓  open source CMS ✓ Schnittstellen etc. für Ihre digitale Herausforderungen.'
        },
        {
          name: 'keywords',
          content: 'programmierung'
        },
        {
          property: 'og:description',
          content:
            'Die holzweg GmbH® bietet Web-Shop Lösungen ✓ PIM ✓ Progressive Web Apps ✓ Individualsoftwarelösungen ✓  CRM ✓  open source CMS ✓ Schnittstellen etc. für Ihre digitale Herausforderungen.'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/technologie-44b3e6232be819c7a7b0f7f0d41929a9.jpg'
        }
      ]}
    />
    {/* <BannerSmall image={BannerImage} title="Technologie. Fortschritt. Erfolg." /> */}
    <BannerHero
      title="WEBSITES MIT PERFORMANCE"
      subtitle="Nutzen Sie Ihre Website als TOOL! Eine Website ist nämlich viel mehr als nur ein Webauftritt!"
      // image={BannerImage}
      video={backgroundVIdeo}
    />
    {/* <TextBox
      title="Maßgeschneiderte Technologie für Ihren Erfolg"
      text={
        <>
          <p>Wir bieten maßgeschneiderte Lösungen für Unternehmen jeder Größe und Branche, von der Konzeption über die Entwicklung bis hin zur Implementierung und Wartung. Dabei setzen wir auf modernste Technologien, um unseren Kund:innen eine erfolgreiche Online-Präsenz bzw. eine bestmögliche Softwarelösung zu ermöglichen. Unsere Lösungen sind maßgeschneidert und berücksichtigen die spezifischen Bedürfnisse und Anforderungen aller Kund:innen. Ob es sich um die Entwicklung eines maßgeschneiderten ERP-Systems, einer mobilen Anwendung oder einer Website handelt, wir haben das Know-how, um komplexe technische Herausforderungen zu meistern.</p>
        </>
      }
    /> */}
    <ImageList />
    <SplitView
      displayImageLeft={true}
      backgroundImage={internet2}
      small={false}
      center={true}
      title="Holen Sie das Maximum aus Ihrer Website"
      linkText="Mehr erfahren"
      backgroundColor="#6fbfd9"
      text=
      {
        <>
          <p>
          Ihr Unternehmen möchte wachsen? Mit den von uns verwendeten Technologien kann Ihre Website mitwachsen. Vielleicht steht eine Shoplösung für Onlineverkäufe im Raum? Melden Sie sich, wir sind drum und dran, Ihnen die besten Lösungen für Ihre individuellen Herausforderungen zu konzipieren und programmieren. 
          </p>
        </>
      }
    />
    <GridList />
    <Appointment image={Georg} />
    <SplitView
      displayImageLeft={true}
      backgroundImage={checkliste}
      title="Digitales Potenzial entfesseln: Wie TYPO3 Ihre Online-Präsenz verbessert"
      link="/hw-erp"
      small={true}
      linkText="Mehr erfahren"
      text=
      {
        <>
          <p>
            Wenn Sie die volle Kontrolle über Ihre Inhalte behalten möchten, bieten TYPO3 Workspaces eine leistungsstarke Lösung. Editoren können in diesen speziellen Bereichen Seiten vorbereiten, ohne dass diese Änderungen sofort öffentlich sichtbar werden. Erst nach Ihrer Freigabe werden die Inhalte live geschaltet.
          </p>
          <br />
          <p>
            Dieses Verfahren ermöglicht eine sorgfältige Überprüfung und stellt sicher, dass nur geprüfte und genehmigte Inhalte auf Ihrer Website erscheinen. Workspaces in TYPO3 unterstützen somit einen strukturierten Workflow und bieten eine effektive Methode zur Inhaltskontrolle und -freigabe.
          </p>
        </>
      }
      listHeading="Was kann Typo3?"
      listDescription="Ein Überblick über die Vorteile"
      lists=
      {[
        {
          head: "Flexibilität und Anpassbarkeit",
          content: [
            "Modulare Architektur für einfache Integration von Funktionen",
            "Anpassbare Templates für individuelle Designs",
            "Mehrsprachige Unterstützung für globale Präsenz"
          ]
        },
        {
          head: "Leistungsstarke Funktionen",
          content: [
            "Intuitive Benutzeroberfläche für einfache Content-Pflege",
            "Umfangreiche Tools für Nutzerverwaltung und Zusammenarbeit",
            "Workflow-Management für effiziente Prozesssteuerung"
          ]
        },
        {
          head: "Sicherheit und Stabilität",
          content: [
            "regelmäßige Updates für Sicherheit",
            "Robuste Architektur für zuverlässige Performance",
            "Zugriffsrechte und Rollenverwaltung für Datenschutz",
          ]
        },
        {
          head: "Skalierbarkeit und Zukunftssicherheit",
          content: [
            "Unterstützung für große und komplexe Websites",
            "Erweiterbarkeit für zukünftige Anforderungen",
            "Langfristige Unterstützung durch engagierte Entwicklergemeinschaft"
          ]
        },
      ]}
    />

    <Testimonials testimonials={testimonials} />

    <BannerCTA
      title="Kontaktieren Sie uns jetzt!"
      text="Bei holzweg steht das Vertrauen unserer Kund:innen an erster Stelle. Wir bauen auf langfristige Beziehungen und Partnerschaften, die auf offener Kommunikation und technischem Know-how basieren.  
    Wenn Sie auf der Suche nach individuellen Softwarelösungen, Custom ERP Systemen oder Websites sind, die auf modernsten Technologien basieren und Ihnen eine erfolgreiche Online-Präsenz ermöglichen, dann sind Sie bei holzweg an der richtigen Adresse."
    />
    <SoftwarePerformance
      image={Motor}
      headline={
        <span>
          Technologische <br /> Leistungssteigerung
        </span>
      }
      text={
        <span>
          Leistungsstarke Technologie ist für uns von zentraler Bedeutung. Sie ermöglicht es uns, unseren Kunden innovative und effiziente Lösungen anzubieten, die ihnen helfen, ihre Ziele zu erreichen. Leistungsstarke Technologie hilft uns, mit den neuesten Trends Schritt zu halten und unseren Kunden immer einen Schritt voraus zu sein. Wir sind überzeugt davon, dass leistungsstarke Technologie ein wesentlicher Faktor für unseren Erfolg ist.
        </span>
      }
    />
    <QuickFacts facts={facts} color="gray" />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Technologie
