import ImageSandra from '../../../assets/Sandra.01.jpg'
import ImageGeorg from '../../../assets/Georg.01.jpg'
import ImageAnja from '../../../assets/portraits/portrait_anja.jpg'
import FunImageSandra from '../../../assets/fun.hover.jpg'

const contacts = [
  {
    name: 'Sandra Mair',
    competence: 'Office',
    shownPhone: '+43 (0) 699 174 074 03',
    phone: '004369917407403',
    email: 'office@holzweg.com',
    image: ImageSandra,
    funImage: FunImageSandra
  },
  {
    name: 'Georg Bader',
    competence: 'Geschäftsführung',
    shownPhone: '+43 (0) 699 174 074 54',
    phone: '004369917407454',
    email: 'georg.bader@holzweg.com',
    image: ImageGeorg,
    funImage: FunImageSandra
  },
  {
    name: 'Anja Friedensdorf',
    competence: 'Projektmanagement',
    shownPhone: '+43 (0) 699 174 074 56',
    phone: '004369917407456',
    email: 'anja.friedensdorf@holzweg.com',
    image: ImageAnja,
    funImage: FunImageSandra
  },
]

export default contacts
